<template>
  <div class="wrap-404 container">
    <ng-image class="not-fine" :src="notFineImg" alt="thumbnail"></ng-image>
    <div>很抱歉，页面不小心迷路了</div>
  </div>
</template>

<script>
  import NgImage from "~/components/NgImage.vue";
  import notFineImg from "../../assets/images/404.png"
  export default {
    components: {NgImage},
    data() {
      return {
        notFineImg
      }
    },
    created() {
      console.log('404');
    }

  };
</script>

<style lang="scss" scoped>
  .wrap-404 {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #000;
    
    .not-fine {
      width: 43.8rem;
      margin-bottom: 3.4rem;
    }
  }

  @media screen and (max-width: $screen-width-md) {
    .wrap-404 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .not-fine {
        width: 90%;
        margin-bottom: 3.4rem;
      }
    }
  }
</style>
